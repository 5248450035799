<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row no-gutters>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search results                                                                                               ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" class="ma-4">
          <v-col xs="12" sm="12" md="12">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="columns"
                :items="taskFlowConfigs"
                :read-path="readPath"
                read-ref="reference"
                title="Task Flow Configs"
                sort-by="created,desc"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VCard, VCol, VContainer, VRow } from "vuetify/lib";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";

export default {
  components: {
    VCard,
    VCol,
    VContainer,
    VRow,
    WEntityList,
  },

  data: () => ({
    taskFlowConfigs: [],
    columns: [
      { value: "name", text: "Name" },
      { value: "reference", text: "Ref." },
      { value: "description", text: "Description" },
    ],
    readPath: path.TASKFLOWCONFIG,
  }),

  watch: {},

  created() {
    this.search();
  },

  methods: {
    search() {
      this.taskFlowConfigs = []; // cleaning the taskFlowConfigs

      // performing the search
      api
        .get("taskflowconfig/list/-1")
        .then((res) => {
          if (res.data.status === 200 && res.data.entities !== null) {
            this.taskFlowConfigs = res.data.entities;
          } else {
            console.log(new Error("Failed searching the process configs"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
